:root {
  --fap-color-primary: #e83534;
  --fap-color-text: #111111;
  --fap-color-border: #dadada;

  --fap-size-max-width: 900px;
  --fap-color-bg: #f6f7f9;

  --mat-primary-color: #3f51b5;
}
